const eMsg = {
    Success: 1,
    Info: 2,
    Error: 3
}

export { eMsg };

export function GetEnumName(Enum, value) {
    return Object.keys(Enum).find(key => Enum[key] === value);
}