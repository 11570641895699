const IsNumber = (str) => {
    return !isNaN(str);
}

const IsEmpty = (str) => {
    if (str && str.trim())
        return false;
    else
        return true;
}

const IsNegativeNum = (str) => {
    return 0 > parseInt(str);
}

const IsUPIID = (str) => {
    const upiRegex = /^[^\s@]+@[^\s@]+$/; // Assumes phone number is exactly 10 digits
    return upiRegex.test(str);
}

export { IsEmpty, IsNumber, IsNegativeNum, IsUPIID }