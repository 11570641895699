import React from 'react';
import '../../CSS/radioButton.css';

export default function radioButton({ onChange, value, Items }) {

    return (
        <>
            {Items ? Items.map((item, index) => (
                <label key={index} className="custom-radio">
                    <input type="radio" checked={value === item[0]} onChange={() => onChange(item[0])} />
                    <span className="custom-radio-button"></span>{item[1]}
                </label>
            )) : ''}
        </>
    )
}
