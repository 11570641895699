import React from 'react';
import imgDashboard from '../../Images/Dashboard.jpg';

export default function Home() {
  return (
    <div style={{ height: '100%' }}>
      <img src={imgDashboard} style={{ width: '100%', height: '100%' }} alt='Dashboard' />
    </div>
  )
}
