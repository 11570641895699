import React, { useEffect } from 'react';

import Invoice from './Pages/Content';
import Header from './Header';
import Sidebar from './Sidebar';
import Items from './Pages/Invoice';
import Config from './Pages/Config';

import img1 from '../Images/logo.png';
import Cookies from 'js-cookie';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Home from './Pages/Home';
import NotFound from './NotFound';

import { useLogin } from './Login';

export default function MainPage() {
  const { setLogin } = useLogin();
  const navigate = useNavigate();

  const Logout = () => {
    setLogin(false);
    Cookies.set('IsLogin', null, 2 * 24 * 3600 * 1000);
    navigate('../login');
  }

  useEffect(() => {
    const IsLogin = Cookies.get('IsLogin');
    if (IsLogin !== 'true') {
      Logout();
    }
  }, []);

  return (
    <>
      <div className='main-panel' style={{ display: 'flex', height: '100vh' }}>
        <div className='side-panel col-lg-2 col-md-2 col-sm-4 col-xs-12'>
          <div style={{ padding: '2vh', backgroundColor: '#1e293b', height: '10vh' }}>
            <img src={img1} className='mr-5px' alt='img' style={{ width: '100%', height: 'auto' }} />
          </div>
          <Sidebar Logout={Logout} />
        </div>
        <div className='body-panel col-lg-10 col-md-10 col-sm-8 col-xs-12' style={{ height: '90vh' }}>
          <div className='header-panel d-flex' style={{ alignItems: 'center', width: '96%', padding: '2vh', height: '5vh' }}>
            <Header />
          </div>
          <div className='content-panel'>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/invoice' element={<Invoice />} />
              <Route path='/items' element={<Items />} />
              <Route path='/config' element={<Config />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  )
}