import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

import html1 from '../Invoices/invoice1.txt';
import html2 from '../Invoices/invoice2.txt';
import html3 from '../Invoices/invoice3.txt';
import html4 from '../Invoices/invoice4.txt';
import { GetDownloadURL, SelectData, sortByField } from '../../Database/db_helper';
import sampleFile from '../../sample_file.xls';

import BharatPay from '../../Images/logos/BharatPay.png';
import GPay from '../../Images/logos/GPay.png';
import Paytm from '../../Images/logos/Paytm.png';
import PhonePay from '../../Images/logos/PhonePay.png';

export default function Content() {
  const [typeError, setTypeError] = useState(null);
  const [Data, setData] = useState([]);
  const [PageData, setPageData] = useState([]);
  const [Items, setItems] = useState([]);
  const [HTML, setHTML] = useState('');
  const [Config, setConfig] = useState('');
  const [Page, setPage] = useState(0);
  const [LogoURL, setLogoURL] = useState('');

  const PrintInvoice = (item, index) => {
    let txt = replaceData(item, HTML, index % Items.length);
    txt = setWatermark(txt);
    txt = txt.replace(/##Name##/g, Config.Name);
    txt = txt.replace('##Address##', Config.Address.replace(/\n/g, ' <br/> '));
    txt = txt.replace('##Bank##', Config.BankName);
    txt = txt.replace('##AccNo##', Config.AccountNo);
    txt = txt.replace('##IFSC##', Config.IFSCCode);
    txt = txt.replace('##UPIID##', Config.UPIID);

    const win = window.open('', '', 'height=400, width=800');
    win.document.write(txt);
    win.document.close();
    win.print();
  }

  const setWatermark = (content) => {
    if (Config.ShowWatermark) {
      const watermark = `<div class='divBG'><div class='my-auto text-center' style='width: 100%;'><img src='${LogoURL}' alt='watermark' class='mx-auto' style='max-width: 80%;'></div></div>`;
      content = content.replace('##WATERMARK##', watermark);
    }
    content = content.replace(/##WATERMARK##/g, '');

    return content;
  }

  const replaceData = (item, body, ItemIndex) => {
    let itm = '';
    let i = 0;
    const Amount = item['Deposit Amt.'] ? parseInt(item['Deposit Amt.']) : 0;

    if (Amount > 0 && Amount <= 2500)
      i = 1;

    if (Amount > 2500 && Amount <= 5000)
      i = 2;

    if (Amount > 5000 && Amount <= 10000)
      i = 3;

    if (Amount > 10000)
      i = 4;

    let j = 0;
    for (j = 0; j < i; j++) {
      let price = parseInt((Amount / ( (i*(i+1)) /2)) + 0.5) * (j+1);
      itm += '<tr class="border-bottom"><td class="text-left padding-3mm">' + (j + 1) + '</td><td class="text-left" style="padding: 3mm;">' + Items[(ItemIndex + j) % (Items.length - 1)].Name + '</td><td style="padding: 3mm;">' + price + '</td></tr>';
    }

    let txt = body;
    txt = txt.replace('##INVOICEDATE##', item['Date']);
    txt = txt.replace('##Narration##', item['Narration']);
    txt = txt.replace('##Items##', itm);
    txt = txt.replace(/##Price##/g, Amount);
    if (item['Chq./Ref.No.']) {
      txt = txt.replace('##RefNo##', item['Chq./Ref.No.']);
    }
    else {
      txt = txt.replace('##RefNo##', '');
      txt = txt.replace('Ref.No.:', '');
    }

    if (item['Value Dt']) {
      txt = txt.replace('##PaymentDate##', item['Value Dt']);
    }
    else {
      txt = txt.replace('##PaymentDate##', '');
      txt = txt.replace('Payment Date:', '');
    }

    return txt;
  }

  const downloadAll = () => {
    let htl = HTML;
    htl = htl.replace(/##Name##/g, Config.Name);
    htl = htl.replace('##Address##', Config.Address.replace(/\n/g, ' <br/> '));
    htl = htl.replace('##Bank##', Config.BankName);
    htl = htl.replace('##AccNo##', Config.AccountNo);
    htl = htl.replace('##IFSC##', Config.IFSCCode);
    htl = htl.replace('##UPIID##', Config.UPIID);

    let content = '';
    PageData.forEach(async (item, index) => {
      content += '<div>' + replaceData(item, htl, index % Items.length) + '</div>';
    });

    content = setWatermark(content);

    const win = window.open('', '', 'height=400, width=800');
    win.document.write(content);
    win.print();

  }

  useEffect(() => {
    setPageData(Data.slice((Page - 1) * 100, Page * 100));
  }, [Page, Data]);

  const getData = async () => {
    const dtItems = await SelectData('InvoiceItem', {});
    sortByField(dtItems, 'SerialNo');
    setItems(dtItems);

    const data = await SelectData('Configuration', {});
    setConfig(data[0]);
    if (data.length > 0) {
      const logourl = await GetDownloadURL(data[0].Logo);
      const qrurl = await GetDownloadURL(data[0].QRCode);
      setLogoURL(logourl);

      const design = data[0].Design;
      let html = '';
      if (design === 4) {
        html = html4;
      }
      else if (design === 3) {
        html = html3;
      }
      else if (design === 2) {
        html = html2;
      }
      else {
        html = html1;
      }

      fetch(html)
        .then(r => r.text())
        .then(text => {
          let htm = text;
          htm = htm.replace(/##Logo##/g, logourl);
          if (data[0].ShowQR) {
            htm = htm.replace('##QRCode##', qrurl);
            htm = htm.replace('##BharatPay##', BharatPay);
            htm = htm.replace('##GPay##', GPay);
            htm = htm.replace('##Paytm##', Paytm);
            htm = htm.replace('##PhonePay##', PhonePay);
          }
          else {
            htm = htm.replace(`<img src='##QRCode##' alt='logo' class='img-logo' style='margin-bottom: 10px;' /><br/>`, '');
            htm = htm.replace(`<img style='height: 30px;' src='##BharatPay##' alt='BharatPay'/>`, '');
            htm = htm.replace(`<img style='margin-left: 5px; height: 30px;' src='##GPay##' alt='GPay'/>`, '');
            htm = htm.replace(`<img style='margin-left: 5px; height: 30px;' src='##Paytm##' alt='Paytm'/>`, '');
            htm = htm.replace(`<img style='margin-left: 5px; height: 30px;' src='##PhonePay##' alt='PhonePay'/>`, '');
          }
          setHTML(htm);
        });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const loadData = (e) => {
    const file = e.target.files[0];
    const fileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
    if (file) {
      if (fileTypes.includes(file.type)) {
        setTypeError(null);

        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (e) => {
          const file = e.target.result;
          const workbook = XLSX.read(file, { type: 'buffer' });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const data = XLSX.utils.sheet_to_json(worksheet);
          setData(data.filter(item => item['Deposit Amt.']));
        }

        setPage(1);
      }
      else {
        setTypeError('Please select only excel file type.');
      }
    }
    else {
      console.log('file not found.');
    }
  }

  return (
    <div>
      <div>
        <div className='d-flex'>
          <div className='upload-file col-lg-3 col-md-4 col-sm-6 col-xs-12'>
            <input type='file' className='form-control' onChange={loadData}></input>
          </div>
          <div className='align-right'>
            <a href={sampleFile} className='asamplefile'><i className='fa fa-download'></i>&nbsp;&nbsp;Sample File</a>
            <button className='ml-10px btn-download-all' onClick={() => downloadAll()}><i className='fa fa-download'></i>&nbsp;&nbsp;Download All</button>
          </div>
        </div>
        {typeError && (<div className=''>{typeError}</div>)}
        <div className='container' style={{ marginTop: '15px', overflow: 'auto', height: '70vh' }}>
          {Data.length > 0 ?
            (<div>
              <table style={{ width: '100%' }}>
                <thead style={{ textAlign: 'justify' }}>
                  <tr>
                    <th>SrNo</th>
                    <th>Invoice Date</th>
                    <th>Chq./Ref.No.</th>
                    <th>Narration</th>
                    <th>Payment Date</th>
                    <th>Deposit Amount</th>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {PageData.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.Date}</td>
                      <td>{item['Chq./Ref.No.']}</td>
                      <td>{item['Narration'].length > 30 ? item['Narration'].substring(0, 30) + '...' : item['Narration']}</td>
                      <td>{item['Value Dt']}</td>
                      <td>{item['Deposit Amt.']}</td>
                      <td>
                        <button className='btn-print' onClick={() => PrintInvoice(item, index + 1)}><i className='fa fa-print'></i> Print</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>)
            :
            (<div>
              Data Not Found
            </div>)}
        </div>
        <div className='d-flex mt-10px'>
          <div className='align-right'>
            <button className='btn-left' onClick={() => { Page > 1 && setPage(Page - 1) }}>&lt;</button>
            <label className='ml-10px'>{Page}</label>
            <button className='ml-10px btn-left' onClick={() => setPage(Page + 1)}>&gt;</button>
          </div>
        </div>
      </div>
    </div>
  )
}