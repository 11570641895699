import React from 'react';

import imgNotFound from '../Images/Error_404.jpg';

export default function NotFound() {
    return (
        <div style={{ width: '100vw', height: '98.5vh' }}>
            <h1 style={{ position: 'fixed', top: '-20vh', left: '33vw', fontSize: '43vh', color: 'white' }}>404</h1>
            <img src={imgNotFound} alt='not found' style={{ width: '100%', height: '100%' }} />
            <h1 style={{ position: 'fixed', top: '58vh', left: '37.5vw', fontSize: '6.1vh', color: 'white' }}>PAGE NOT FOUND</h1>
        </div>
    )
}
