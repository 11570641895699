import React, { useEffect, useState } from 'react';
import { InsertData, SelectDataById, UpdateData } from '../../../Database/db_helper';
import { IsEmpty, IsNegativeNum, IsNumber } from '../../../Database/validation';
import { useAlert } from '../../common/message';
import { eMsg } from '../../common/enum';

import '../../../CSS/popup.css';

export default function PopupInvoiceItem({ InvoiceId, Type, onClose }) {
    const showMsg = useAlert();
    const [name, setName] = useState(' ');
    const [serialNo, setSerialNo] = useState(0);

    const updateName = (e) => { setName(e.target.value); }
    const updateSerialNo = (e) => { setSerialNo(e.target.value); }

    const IsValid = () => {
        if (IsEmpty(name)) {
            showMsg(eMsg.Info, 'Name Is Required.');
            return false;
        }

        if (IsEmpty(serialNo)) {
            showMsg(eMsg.Info, 'SerialNo is Required.');
            return false;
        }
        else if (!IsNumber(serialNo)) {
            showMsg(eMsg.Info, 'SerialNo must be number.');
            return false;
        }
        else if (IsNegativeNum(serialNo)) {
            showMsg(eMsg.Info, 'SerialNo must be greater than zero(0).');
            return false;
        }

        return true;
    }

    const SaveData = async () => {
        if (!IsValid()) {
            return;
        }

        const objItem = {
            Name: name,
            SerialNo: parseInt(serialNo)
        }

        if (Type === 'Edit') {
            await UpdateData('InvoiceItem', InvoiceId, objItem);

            showMsg(eMsg.Success, 'Item Updated Successfully.');
        }
        else {
            await InsertData('InvoiceItem', objItem);

            showMsg(eMsg.Success, 'Item Inserted Successfully.');
        }

        onClose();
    }

    const setData = async () => {
        const obj = await SelectDataById('InvoiceItem', InvoiceId);
        if (obj) {
            setName(obj.Name);
            setSerialNo(obj.SerialNo);
        }
    }

    useEffect(() => {
        setData();
    }, []);

    return (
        <div className='popup-bg'>
            <div className='popup-body my-auto row mx-auto col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                <div className='form-group'>
                    <div className='popup-header w-full'>
                        <div className='float-right'>
                            <button onClick={() => onClose()} className='btn-close'>x</button>
                        </div>
                        <div className='popup-title'>
                            <h3 className='mt-0'>{Type} Item</h3>
                        </div>
                    </div>
                </div>
                <div className='form-group'>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12 my-auto'>
                        <label className='control-label'>Name</label>
                    </div>
                    <div className='col-lg-9 col-md-9 col-sm-6 col-xs-12'>
                        <input type='text' value={name} onChange={(e) => updateName(e)} className='form-control'></input>
                    </div>
                </div>
                <div className='form-group'>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12 my-auto'>
                        <label className='control-label'>SerialNo</label>
                    </div>
                    <div className='col-lg-9 col-md-9 col-sm-6 col-xs-12'>
                        <input type='text' value={serialNo} onChange={(e) => updateSerialNo(e)} className='form-control'></input>
                    </div>
                </div>
                <div className='mt-20px'>
                    <button className='btn-save' onClick={() => SaveData()}>Save</button>
                    <button className='btn-cancel ml-10px' onClick={() => onClose()}>Cancel</button>
                </div>
            </div>
        </div>
    )
}
