import React, { useState } from 'react'
import { Link } from 'react-router-dom';

export default function Sidebar({ Logout }) {

    const [tab, setTab] = useState(0);

    return (
        <div style={{ paddingTop: '2vh', height: '84vh', color: 'white', backgroundColor: '#1e293b' }}>
            <Link to='/' className={`tab ${tab === 0 ? 'tab-active' : ''}`} onClick={() => setTab(0)}>
                <i className='fa fa-home'></i>&nbsp;Home
            </Link>
            <Link to='/invoice' className={`tab ${tab === 1 ? 'tab-active' : ''}`} onClick={() => setTab(1)}>
                <i className='fa fa-file-text-o'></i>&nbsp;Invoice
            </Link>
            <Link to='/items' className={`tab ${tab === 2 ? 'tab-active' : ''}`} onClick={() => setTab(2)}>
                <i className='fa fa-cubes'></i>&nbsp;Items
            </Link>
            <Link to='/config' className={`tab ${tab === 3 ? 'tab-active' : ''}`} onClick={() => setTab(3)}>
                <i className='fa fa-cogs'></i>&nbsp;Configuration
            </Link>
            <div className='tab' onClick={() => Logout()}>
                <i className='fa fa-sign-in'></i>&nbsp;Logout
            </div>
        </div>
    )
}