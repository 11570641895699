import { initializeApp } from "firebase/app";
import { getFirestore } from '@firebase/firestore';
import { getStorage } from '@firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDksU6TDbRSLwHXi7BmbwJI4r1JFFJ7pYs",
    authDomain: "invoice-6fa18.firebaseapp.com",
    projectId: "invoice-6fa18",
    storageBucket: "invoice-6fa18.appspot.com",
    messagingSenderId: "887910653137",
    appId: "1:887910653137:web:a7ccb12762d123a1f879f0"
};

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const storage = getStorage(app);