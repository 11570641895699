import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import '../CSS/login.css';

const LoginContext = createContext();
export const useLogin = () => useContext(LoginContext);

export const LoginDetails = ({ children }) => {
    const CookieLogin = Cookies.get('IsLogin');
    const [IsLogin, setLogin] = useState(CookieLogin && 'true' === CookieLogin.toLowerCase());

    return (
        <LoginContext.Provider value={{ IsLogin, setLogin }}>
            {children}
        </LoginContext.Provider>
    );
}

export default function Login() {
    const navigate = useNavigate();
    const { IsLogin, setLogin } = useLogin();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const CheckLogin = (e) => {
        e.preventDefault();
        if (username === 'admin' && password === '12345') {
            Cookies.set('IsLogin', true, { expires: 2*24*3600*1000, path: '/' });
            setError('');
            setLogin(true);
            navigate('../');
        }
        else {
            setLogin(false);
            setError('user not found');
        }
    }

    useEffect(() => {
        if (IsLogin) {
            navigate('../');
        }
    }, []);

    return (
        <div className='login-panel'>
            <div className='wrapper'>
                <form action='' onSubmit={CheckLogin}>
                    <h1>Sign-In</h1>
                    <div className='input-box d-flex'>
                        <input type="text" placeholder="Username" value={username} required onChange={(e) => setUsername(e.target.value)} />
                    </div>
                    <div className='input-box d-flex'>
                        <input type="password" placeholder="Password" value={password} required onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className='remember'>
                        {error && (<div className='login-error'>{error}</div>)}
                    </div>
                    <button type='submit' className='btn-login'>Sign-In</button>
                </form>
            </div>
        </div>
    )
}
