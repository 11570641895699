import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './CSS/style.css';
import './CSS/style2.css';
import Message from './Components/common/message';
import LoginPanel, { LoginDetails } from './Components/Login';
import MainPage from './Components/MainPage';
import NotFound from './Components/NotFound';

function App() {
  return (
    <BrowserRouter>
      <LoginDetails>
        <Message>
          <div className="App">
            <Routes>
              <Route path='/*' element={<MainPage />} />
              <Route path='/login' element={<LoginPanel />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </div>
        </Message>
      </LoginDetails>
    </BrowserRouter>
  );
}

export default App;
